import React from 'react';
import { Popup } from 'react-leaflet';
import { usePlace } from '../../hooks/usePlace';
import { PlaceInfo } from './PlaceInfo';

export const MapInfoPopup: React.FC<{ placeId: Place['id'] }> = ({ placeId }) => {
  const { data, isLoading, error } = usePlace(placeId);

  return (
    <Popup>
      {isLoading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error.message}</p>
      ) : data ? (
        <PlaceInfo place={data} />
      ) : (
        <p>No data available</p>
      )}
    </Popup>
  );
};