import { useQuery } from 'react-query';
import { useAPIBase } from './use-api-base';

export function usePlace(placeId: Place['id']) {
  const base = useAPIBase();
  return useQuery<Place, Error>(
    ['place', placeId],
    async () => {
      const response = await fetch(`${base}/place/${placeId}/`);
      if (!response.ok) {
        throw new Error('Failed to fetch place data');
      }
      return response.json();
    },
    { enabled: !!placeId }
  );
}